import React from 'react';

const Certificates = (props) => {
    console.log("prop", props);
    return (
        <div style={{border: "1px solid #ed3136", borderRadius: "15px"}}>
            <div style={{
                backgroundColor: "#f7f7f7",
                color: "#ed3136",
                borderTopLeftRadius: "15px",
                borderTopRightRadius: "15px",
                textAlign: "center",
                fontSize: "15px",
                padding: "7px 15px"}}>
                Cupom
            </div>
            <div style={{
                backgroundColor: "#ed3136",
                color: "#f7f7f7",
                textAlign: "center",
                fontSize: "18px",
                fontWeight: "bolder",
                padding: "7px 15px",
                borderBottomLeftRadius: "15px",
                borderBottomRightRadius: "15px"}}>
                {props.coupon.Certificate}
            </div>
        </div>
    )
}

export default Certificates;