import React, { Component } from 'react';
import Theme from './Theme';

const Steps = (props) => {
    return (
        <div>
            <div>
                <span style={{...Theme.palette.stepArround}}>{props.number}</span>
                <span style={{...Theme.palette.stepText}}>{props.text}</span>
            </div>
            <div style={{display: (props.last) ? "none" : "block"}}>
                <span style={{...Theme.palette.stepBetween}}></span>
            </div>
        </div>
    )
}

export default Steps;