import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Modal } from '@material-ui/core';
import Theme from './Theme';

function getModalStyle() {

    const left = ((window.innerWidth - 400) / 2) + "px";
    const top = "50px";

    return {
        top: `${top}`,
        left: `${left}`,
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 370,
        backgroundColor: "#fff",
        border: '0px solid',
        boxShadow: theme.shadows[5],
        borderRadius: "20px",
        padding: "15px"
    },
}));

export default function AlertModal(props) {
    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);
    const [open] = React.useState(props.open || false);
    const [title] = React.useState(props.title || "Alerta");
    const [message] = React.useState(props.message || false);
    const [body] = React.useState(props.body || false);

    const handleClose = () => {
        props.handleClose(false);
    };

    const _body = (
        <div>
            <h2 style={Theme.palette.titleModel}>{title}</h2>

            {Array.isArray(message) && message.map((msg, index) => {
                return (
                    <p key={index} style={Theme.palette.bodyModel}>{msg}</p>
                );
            })}

            {typeof message === "string" && (
                <p style={Theme.palette.bodyModel}>{message}</p>
            )}

            <div style={{...Theme.palette.spaceButtonOkModal, ...Theme.palette.divCenter}}>
                <Button style={Theme.palette.buttonRed} onClick={handleClose}>
                    ok
                </Button>
            </div>
        </div>
    );

    return (
        <Modal
            disablePortal
            disableEnforceFocus
            disableScrollLock
            disableAutoFocus
            aria-labelledby="server-modal-title"
            aria-describedby="server-modal-description"
            open={open}>
            <div style={modalStyle} className={classes.paper}>
                {body || _body}
            </div>
        </Modal>
    );
}