import React, { useContext, useEffect, useState } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import logo from "../../images/nsr-premiada.png";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Theme from '../../components/views/Theme';
import CloseIcon from '@material-ui/icons/Close';
import ReceiptIcon from '@material-ui/icons/Receipt';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import MenuIcon from '@material-ui/icons/Menu';
import SettingsIcon from '@material-ui/icons/Settings';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LocalPlayIcon from '@material-ui/icons/LocalPlay';
import { useNavigate } from 'react-router-dom';
import { logout, getToken, getLoggedName, setLoggedName, setTokenFile } from '../../components/infrastructures/Auth';
import { useApplicationContext } from '../../components/infrastructures/Context';
import { RequestGetInformation } from "../../components/infrastructures/UrlEndpoints";
import { changeUserName } from "../../components/infrastructures/Reducer";
import Http from "../../components/infrastructures/Http";

export default function Main(props) {

    let navigate = useNavigate();
    const [application, dispatch] = useApplicationContext();

    useEffect(() => {
        let token = getToken();
        if (!token) {
            setLoggedName("");
            logout();
            navigate("/");
        } else {
            let name = getLoggedName();
            if (name) {
                dispatch(changeUserName(name));
            } else {
                getInformation();
            }
        }
    },[]);

    const getInformation = async () => {
        await Http({
            method: "GET",
            url: RequestGetInformation(),
        }).then(response => {
            setLoggedName(response.data.Name);
            dispatch(changeUserName(response.data.Name));
        }).catch(err => {
            dispatch(changeUserName("Contribuinte"));
        });
    }

    const handleOpenMenu = (event) => {
        event.preventDefault();
        let menuWidth = "350px";
        if (window.innerWidth < 400) {
            menuWidth = "70%";
        }
        document.getElementById("mySidenav").style.width = menuWidth; //opens side navbar by 70 percent
    }

    const handleCloseMenu = (event) => {
        event.preventDefault();
        document.getElementById("mySidenav").style.width = "0";
    }

    const handleLink = (event, link) => {
        event.preventDefault();
        handleCloseMenu(event);
        navigate(link);
    }

    const handleLogout = (event) => {
        event.preventDefault();
        setLoggedName("");
        logout();
        navigate("/");
    }

    return (
        <div>
            <div id="mySidenav" className="sidenav-container">
                <span style={{...Theme.palette.closeMenu}}>
                    <IconButton aria-label="CloseIcon" onClick={(event) => handleCloseMenu(event)}>
                        <CloseIcon style={{...Theme.palette.textColorWhite}} />
                    </IconButton>
                </span>

                <div style={{...Theme.palette.mt20, ...Theme.palette.mb20}}>
                    <AccountCircleIcon style={{...Theme.palette.iconUserMenu}}/>
                    <div style={{...Theme.palette.nameUserMenu}}>{application.UserName}</div>
                </div>

                <List style={{...Theme.palette.listMenu}}>
                    <ListItem button onClick={(event) => handleLink(event, "/notas")}>
                        <ListItemIcon><ReceiptIcon style={{...Theme.palette.textColorWhite}} /></ListItemIcon>
                        <ListItemText primary="Cadastrar notas" />
                    </ListItem>
                    <ListItem button onClick={(event) => handleLink(event, "/listar")}>
                        <ListItemIcon><FormatListBulletedIcon style={{...Theme.palette.textColorWhite}} /></ListItemIcon>
                        <ListItemText primary="Minhas notas" />
                    </ListItem>
                    <ListItem button onClick={(event) => handleLink(event, "/meus-cupons")}>
                        <ListItemIcon><LocalPlayIcon style={{...Theme.palette.textColorWhite}} /></ListItemIcon>
                        <ListItemText primary="Meus cupons" />
                    </ListItem>
                    <ListItem button onClick={(event) => handleLink(event, "/menus-dados")}>
                        <ListItemIcon><SettingsIcon style={{...Theme.palette.textColorWhite}} /></ListItemIcon>
                        <ListItemText primary="Meus dados" />
                    </ListItem>
                    <ListItem button onClick={(event) => handleLink(event, "/alterar-senha")}>
                        <ListItemIcon><LockOpenIcon style={{...Theme.palette.textColorWhite}} /></ListItemIcon>
                        <ListItemText primary="Alterar minha senha" />
                    </ListItem>
                    <ListItem button onClick={(event) => handleLogout(event)}>
                        <ListItemIcon><ExitToAppIcon style={{...Theme.palette.textColorWhite}} /></ListItemIcon>
                        <ListItemText primary="Sair" />
                    </ListItem>
                </List>
            </div>

            <nav style={{height: "100px"}}>
                <IconButton aria-label="menu" onClick={(event) => handleOpenMenu(event)}>
                    <MenuIcon style={{...Theme.palette.textColorWhite}}/>
                </IconButton>
                <img src={logo} style={{height: "90px", marginLeft: "20px", marginRight: "20px"}} alt="Nova Santa Rita Premiada" />
                <Button style={{...Theme.palette.buttonRegisterInvoice}} onClick={(event) => handleLink(event, "/notas")}>
                    Cadastrar notas
                </Button>
            </nav>

            <div style={{...Theme.palette.bodySystem}}>
                {props.children}
            </div>
        </div>
    );
}