export const getUrl = () => {
    let url = "http://localhost:4500";
    if (process.env.REACT_APP_ENV === "development") {
        url = "http://localhost:4500";
    } else if (process.env.REACT_APP_ENV === "test") {
        url = "https://app-homol.apinovasantaritapremiada.com.br/";
    } else if (process.env.REACT_APP_ENV === "production") {
        url = "https://apipremiada.novasantarita.rs.gov.br";
    }
    return url;
}