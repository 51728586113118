import React from 'react';
import Theme from './Theme';

const TaxPayerStep = (props) => {

    let color = (props.active) ? "#fff" : "#ed3136";
    let bg = (props.active) ? "#ed3136" : "transparent";

    return (
        <div style={{
            display:"flex",
            alignItems:"center",
            justifyContent:"center",
            cursor:"pointer"}}>
            <div style={{
                width:"28px",
                height:"28px",
                backgroundColor: bg,
                display:"flex",
                alignItems:"center",
                justifyContent:"center",
                borderRadius:"30px",
                border: "1px solid #ed3136",
                color: color}}>
                {props.step}
            </div>
            <div style={{
                marginLeft: "3px",
                color: "#ed3136"}}>
                {props.title}
            </div>
        </div>

    )
}

export default TaxPayerStep;