import React, { useState, useEffect } from "react";
import { Button, TextField, Typography } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import Theme from '../../../components/views/Theme';
import { maskMoney  } from "../../../components/views/Masks";
import { convertToDecimal } from "../../../components/views/Functions";
import StepByStep from "../../../components/views/StepByStep";

const StepValue = (props) => {

    let data = props.data;

    const [value, setValue] = useState("");

    const [error, setError] = useState({
        value: {validate: true, message: null}
    });

    useEffect(() => {
        setValue(maskMoney(data.value) || "");
    }, [data]);

    const validateForm = () => {
        let _error = {...error};
        let _verify = false;

        if (value.trim() === "" || value.trim() === "0,00") {
            _error.value.validate = false;
            _error.value.message = "Valor deve ser maior que zero";
            _verify = true;
        }

        setError(_error);
        return _verify;
    }

    const handleSubmitForm = (event) => {
        event.preventDefault();

        if (validateForm()) return;

        props.setPreviewData({
            value: convertToDecimal(value)
        });

        props.setStep(4);
    }

    const buttonStyle = () => {
        if (value.trim() === "" || value.trim() === "0,00") {
            return {...Theme.palette.buttonRedDisable, ...Theme.palette.spaceBottom};
        }

        return {...Theme.palette.buttonRed, ...Theme.palette.spaceBottom};
    }

    return (
        <div>
            <div style={{...Theme.palette.divTitleRegisterInvoices}}>
                <Typography variant="h6" component="h1" align="left" style={Theme.palette.textColorRed}>Cadastre o número da nota fiscal</Typography>
            </div>

            <ThemeProvider theme={Theme}>
                <TextField
                    value={value}
                    onChange={(event) => {
                        event.preventDefault();
                        let _valeu = maskMoney(event.target.value);
                        setValue(_valeu);
                    }}
                    onFocus={(event) => {
                        let _error = {...error};
                        _error.value.validate = true;
                        _error.value.message = "";
                        setError(_error);
                    }}
                    error={!error.value.validate}
                    helperText={error.value.message}
                    color="primary"
                    id="value"
                    name="value"
                    label="Valor (R$)"
                    size="small"
                    margin="dense"
                    type="text"
                    placeholder="Preencha o campo"
                    fullWidth
                />
            </ThemeProvider>

            <div style={{...Theme.palette.divSpaceLink, ...Theme.palette.divCenter}}>
                <StepByStep size={3} active={3} />
            </div>

            <div style={{...Theme.palette.divCenter}}>
                <Button disabled={value.trim() === "" || value.trim() === "0,00"} style={buttonStyle()} onClick={handleSubmitForm}>
                    Próximo
                </Button>
            </div>
        </div>
    );
}

export default StepValue;