import React from 'react';

const BoxInformationList = (props) => {
    return (
        <div style={{backgroundColor: "#fff", borderRadius: "20px"}}>
            <div style={{padding: "15px"}}>
                <p style={{fontSize: "10px", color: "#707070", margin:"0px"}}>{props.title}</p>
                <p style={{fontSize: "18px", color: "#ed3136", margin:"5px 0px 0px 0px", fontWeight: "bold"}}>{props.info}</p>
            </div>
        </div>
    )
}

export default BoxInformationList;