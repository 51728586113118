import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Button, TextField, Typography } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import Theme from '../../components/views/Theme'
import logo from "../../images/nsr-premiada.png"
import { validateCpf, validateFone } from "../../components/views/Functions";
import { maskCpf, maskFone } from "../../components/views/Masks";
import PageLoad from "../../components/views/PageLoad";
import Http from "../../components/infrastructures/Http"
import successIcon from "../../images/success-icon.png"
import AlertModal from "../../components/views/AlertModal";
import { RequestRegister } from "../../components/infrastructures/UrlEndpoints";

const Register = () => {
    let navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [keyLoading, setKeyLoading] = useState(Math.random());

    const [modalSuccess, setModalSuccess] = useState(false);
    const [keyModalSuccess, setKeyModalSuccess] = useState(Math.random());

    const [modalError, setModalError] = useState(false);
    const [keyModalError, setKeyModalError] = useState(Math.random());

    const [message] = useState([
        "Falha inesperada.",
        "Entre em contato com o administrador."
    ]);

    const [name, setName] = useState("");
    const [cpf, setCpf] = useState("");
    const [fone, setFone] = useState("");
    const [password, setPassword] = useState("");
    const [confirm, setConfirm] = useState("");

    const [error, setError] = useState({
        name: {validate: true, message: null},
        cpf: {validate: true, message: null},
        fone: {validate: true, message: null},
        password: {validate: true, message: null},
        confirm: {validate: true, message: null}
    });

    const [visibleTerm, setVisibleTerm] = useState(false);

    const validateForm = () => {
        let _error = {...error};
        let _verify = false;
        if (name === "") {
            _error.name.validate = false;
            _error.name.message = "Por favor, insira seu nome. Campo é obrigatório.";
            _verify = true;
        }

        if (cpf === "") {
            _error.cpf.validate = false;
            _error.cpf.message = "Por favor, insira seu cpf. Campo é obrigatório.";
            _verify = true;
        } else if (!validateCpf(cpf)) {
            _error.cpf.validate = false;
            _error.cpf.message = "Campo Cpf inválido.";
            _verify = true;
        }

        if (fone === "") {
            _error.fone.validate = false;
            _error.fone.message = "Por favor, insira seu telefone. Campo é obrigatório.";
            _verify = true;
        } else if (validateFone(fone)) {
            _error.fone.validate = false;
            _error.fone.message = "ampo Telefone inválido.";
            _verify = true;
        }

        if (password === "") {
            _error.password.validate = false;
            _error.password.message = "Por favor, insira sua senha. Campo é obrigatório.";
            _verify = true;
        } else if (password.length < 6) {
            _error.password.validate = false;
            _error.password.message = "Senha deve ter mais de 6 digitos.";
            _verify = true;
        }

        if (confirm === "") {
            _error.confirm.validate = false;
            _error.confirm.message = "Por favor, insira confirmação de senha. Campo é obrigatório.";
            _verify = true;
        } else if (password !== confirm) {
            _error.confirm.validate = false;
            _error.confirm.message = "Senhas são diferentes.";
            _verify = true;
        }

        setError(_error);
        return _verify;
    }

    const _setLoading = (option) => {
        setLoading(option);
        setKeyLoading(Math.random());
    }

    const _setMessageSuccess = (option) => {
        setModalSuccess(option);
        setKeyModalSuccess(Math.random());
    }

    const _setMessageError = (option) => {
        setModalError(option);
        setKeyModalError(Math.random());
    }

    const handleSubmitForm = (event) => {
        event.preventDefault();

        if (validateForm()) return;

        _setLoading(true);

        Http({
            method: "POST",
            url: RequestRegister(),
            data: {
                Name: name,
                Cpf: cpf,
                Login: cpf,
                Fone: fone,
                Password: password,
                Confirm: confirm
            }
        }).then(response => {
            _setLoading(false);
            if (response.status === 200) {
                _setMessageSuccess(true);
            } else {
                _setMessageError(true);
            }
        }).catch(err => {
            _setLoading(false);
            let _error = {...error};
            if (err.response.status == 400) {
                if (Array.isArray(err.response.data.message)) {
                    err.response.data.message.map(item => {
                        _error[item.field.toLowerCase()] = {
                            validate: false,
                            message: item.description.join()
                        }
                    });
                    setError(_error);
                }
            }
            _setMessageError(true);
        });
    }

    const getSuccessMessage = () => {
        return (
            <div style={{...Theme.palette.divModalRegisterSuccess}}>
                <div>
                    <img src={successIcon} className="img-success-icon mb20" alt="Icone sucesso" />
                </div>

                <div>
                    <p style={{...Theme.palette.textModalRegisterSuccessPrimary}}>Cadastro realizado</p>
                    <p style={{...Theme.palette.textModalRegisterSuccessPrimary}}>com sucesso</p>
                </div>

                <br />

                <div>
                    <p style={{...Theme.palette.textModalRegisterSuccessSecundary}}>Clique a baixo e</p>
                    <p style={{...Theme.palette.textModalRegisterSuccessSecundary}}>comece a cadastrar</p>
                    <p style={{...Theme.palette.textModalRegisterSuccessSecundary}}>suas Notas fiscais.</p>
                </div>

                <br />

                <div>
                    <Button style={{...Theme.palette.buttonRed, ...Theme.palette.spaceBottom}} onClick={(event) => redirectLogin(event)}>
                        Fazer Login
                    </Button>
                </div>
            </div>
        )
    }

    const handleBack = (event) => {
        event.preventDefault();
        navigate(`/`);
    }

    const handleVisibleTerm = (event) => {
        event.preventDefault();
        setVisibleTerm(!visibleTerm);
    }

    const redirectLogin = (event) => {
        event.preventDefault();
        navigate("/login");
    }

    return (
        <div style={Theme.palette.divPageLogin}>
            <form>
                <PageLoad open={loading} key={keyLoading} />

                <AlertModal
                    open={modalSuccess}
                    handleClose={_setMessageSuccess}
                    message={null}
                    title={null}
                    body={getSuccessMessage()}
                    key={keyModalSuccess}
                />

                <AlertModal
                    open={modalError}
                    handleClose={_setMessageError}
                    message={message}
                    title="Erro"
                    key={keyModalError}
                />

                <div style={Theme.palette.divCenter}>
                    <img src={logo} style={Theme.palette.imgPageLogin} alt="Nova Santa Rita Premiada" />
                </div>

                <div style={{display: (visibleTerm) ? "block" : "none"}}>
                    <Typography variant="h6" component="h1" align="left" style={Theme.palette.textColorRed}>Regras do cadastro</Typography>

                    <div style={{...Theme.palette.mt40}}>
                        <p style={{...Theme.palette.textTerm}}>Certifique-se de que a Nota esteja bem</p>
                        <p style={{...Theme.palette.textTerm}}>focada e que toas as informações</p>
                        <p style={{...Theme.palette.textTerm}}>estejam enquadradas na foto.</p>
                    </div>

                    <div style={{...Theme.palette.mt40}}>
                        <p style={{...Theme.palette.textTerm}}>Ao digitar os dados (número e valor da nota)</p>
                        <p style={{...Theme.palette.textTerm}}>verifique se todos estão corretos.</p>
                    </div>

                    <div style={{...Theme.palette.mt40}}>
                        <p style={{...Theme.palette.textTerm}}>Caso alguma das informações citada a cima</p>
                        <p style={{...Theme.palette.textTerm}}>estiverm fora das normas, nossos agentes</p>
                        <p style={{...Theme.palette.textTerm}}>rejeitaram a nota fiscal.</p>
                    </div>

                    <div style={{...Theme.palette.mt40}}>
                        <div style={{...Theme.palette.divCenter}}>
                            <Button style={{...Theme.palette.buttonRed, ...Theme.palette.spaceBottom}} onClick={(event) => handleVisibleTerm(event)}>
                                Voltar
                            </Button>
                        </div>
                    </div>
                </div>

                <div style={{display: (!visibleTerm) ? "block" : "none"}}>
                    <Typography variant="h6" component="h1" align="left" style={Theme.palette.textColorRed}>Cadastre-se</Typography>

                    <ThemeProvider theme={Theme}>
                        <TextField
                            value={name}
                            onChange={(event) => setName(event.target.value)}
                            onFocus={(event) => {
                                let _error = {...error};
                                _error.name.validate = true;
                                _error.name.message = "";
                                setError(_error);
                            }}
                            error={!error.name.validate}
                            helperText={error.name.message}
                            color="primary"
                            id="name"
                            name="name"
                            label="Nome"
                            size="small"
                            margin="dense"
                            type="text"
                            placeholder="Digite seu nome"
                            fullWidth
                        />

                        <TextField
                            value={cpf}
                            onChange={(event) => {
                                let _cpf = maskCpf(event.target.value);
                                setCpf(_cpf);
                            }}
                            onFocus={(event) => {
                                let _error = {...error};
                                _error.cpf.validate = true;
                                _error.cpf.message = "";
                                setError(_error);
                            }}
                            error={!error.cpf.validate}
                            helperText={error.cpf.message}
                            color="primary"
                            id="cpf"
                            name="cpf"
                            label="Cpf"
                            size="small"
                            margin="dense"
                            type="text"
                            placeholder="Digite seu Cpf"
                            fullWidth
                        />

                        <TextField
                            value={fone}
                            onChange={(event) => {
                                let _fone = maskFone(event.target.value);
                                setFone(_fone);
                            }}
                            onFocus={(event) => {
                                let _error = {...error};
                                _error.fone.validate = true;
                                _error.fone.message = "";
                                setError(_error);
                            }}
                            error={!error.fone.validate}
                            helperText={error.fone.message}
                            color="primary"
                            id="fone"
                            name="fone"
                            label="Telefone ou celular"
                            size="small"
                            margin="dense"
                            type="text"
                            placeholder="Digite seu número de telefone ou celular"
                            fullWidth
                        />

                        <TextField
                            value={password}
                            onChange={(event) => setPassword(event.target.value)}
                            onFocus={(event) => {
                                let _error = {...error};
                                _error.password.validate = true;
                                _error.password.message = "";
                                setError(_error);
                            }}
                            error={!error.password.validate}
                            helperText={error.password.message}
                            color="primary"
                            id="password"
                            name="password"
                            label="Senha"
                            size="small"
                            margin="dense"
                            type="password"
                            placeholder="Digite sua senha"
                            fullWidth
                        />

                        <TextField
                            value={confirm}
                            onChange={(event) => setConfirm(event.target.value)}
                            onFocus={(event) => {
                                let _error = {...error};
                                _error.confirm.validate = true;
                                _error.confirm.message = "";
                                setError(_error);
                            }}
                            error={!error.confirm.validate}
                            helperText={error.confirm.message}
                            color="primary"
                            id="confirm"
                            name="confirm"
                            label="Confirmar senha"
                            size="small"
                            margin="dense"
                            type="password"
                            placeholder="Digite sua senha"
                            fullWidth
                        />
                    </ThemeProvider>

                    <div style={{...Theme.palette.divCenter, ...Theme.palette.mt20, ...Theme.palette.mb20}}>
                        <p style={{...Theme.palette.termRegister}}>Ao clicar em Finalizar você estará</p>
                        <p style={{...Theme.palette.termRegister}}>concordando com os</p>
                        <p style={{...Theme.palette.termRegister}}>
                            <a style={{...Theme.palette.linkTermRegister}} onClick={(event) => handleVisibleTerm(event)}>Termos de Responsabilidades</a>
                        </p>
                    </div>

                    <div style={{...Theme.palette.divCenter}}>
                        <Button style={{...Theme.palette.buttonRed, ...Theme.palette.spaceBottom}} onClick={handleSubmitForm}>
                            Finalizar
                        </Button>
                    </div>

                    <div style={{...Theme.palette.divCenter}}>
                        <Button style={{...Theme.palette.buttonWhite}} onClick={handleBack}>
                            Cancelar
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default Register;