export const ALTER_USER_NAME = 'APP/ALTER_USER_NAME';

export const initialState = {
  UserName: ""
};

export const changeUserName = (UserName) => ({
    type: ALTER_USER_NAME,
    UserName,
});

export const changeUserNameReducer = (state = initialState, action) => {
    if (action.type === ALTER_USER_NAME) {
      return {
        ...state,
        UserName: action.UserName
      };
    }
};