import React, { useState } from 'react';
import Steps from '../../../components/views/Steps';
import { Button, Typography } from '@material-ui/core';
import Theme from '../../../components/views/Theme';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { ThemeProvider } from '@material-ui/styles';
import { useNavigate } from 'react-router-dom';
import AlertModal from "../../../components/views/AlertModal";

const StepInformation = (props) => {

    let navigate = useNavigate();

    const [open, setOpen] = useState(false);
    const [message] = useState([
        "Certifique-se de que a Nota esteja bem",
        "focada e que todas as informações",
        "estejam enquadradas na foto.",
        "",
        "Ao digitar os dados (número e valor da nota)",
        "verifique se todos estão corretos.",
        "",
        "Caso alguma das informações citada a cima",
        "estiverm fora das normas, nossos agentes",
        "rejeitaram a nota fiscal."
    ]);

    const handleVisibleTerm = (event) => {
        event.preventDefault();
        setOpen(true);
    }

    const handleClose = (event) => {
        setOpen(false);
    }

    const handleStepToStep = () => {
        localStorage.setItem("notShowMessage", true);
    }

    const handleSubmitForm = (event) => {
        event.preventDefault();
        props.setStep(1);
    }

    const handleBack = (event) => {
        event.preventDefault();
        navigate("/sistema");
    }

    return (
        <div>

            <AlertModal
                open={open}
                handleClose={handleClose}
                message={message}
                title="Regras do cadastro"
                key={open}
            />

            <div style={{...Theme.palette.divTitleRegisterInvoices}}>
                <Typography variant="h6" component="h1" align="left" style={Theme.palette.textColorRed}>Passo a passo</Typography>
                <p style={{...Theme.palette.subTitleRegisterInvoices}}>Aprenda como cadastrar as notas fiscais</p>
            </div>

            <Steps number="1" text="Tire uma foto ou faça o upload do pdf da NF" last={false}/>
            <Steps number="2" text="Cadastre o número da nota filcal" last={false}/>
            <Steps number="3" text="Cadastre o valor da nota fiscal" last={false}/>
            <Steps number="4" text="Finalize" last={true}/>

            <div style={{...Theme.palette.divSpaceLinkStepInfo}}>
                <a style={{...Theme.palette.linkStepInfo}} onClick={(event) => handleVisibleTerm(event)}>Regras do Cadastro</a>
            </div>

            <div style={{...Theme.palette.divCenter}}>
                <Button style={{...Theme.palette.buttonRed, ...Theme.palette.spaceBottom}} onClick={handleSubmitForm}>
                    Iniciar
                </Button>
            </div>

            <div style={{...Theme.palette.divCenter}}>
                <Button style={{...Theme.palette.buttonWhite}} onClick={handleBack}>
                    Voltar
                </Button>
            </div>

            <div style={{...Theme.palette.divSpaceLinkStepInfo}}>
                <ThemeProvider theme={Theme}>
                    <FormControl component="fieldset">
                        <FormGroup aria-label="position" row>
                            <FormControlLabel
                                value="end"
                                control={<Switch color="secondary" onChange={() => handleStepToStep()}/>}
                                label={<span style={{...Theme.palette.linkStepInfo}}>Não quero mais visualizar este Passo a Passo</span>}
                                labelPlacement="end"
                            />
                        </FormGroup>
                    </FormControl>
                </ThemeProvider>
            </div>
        </div>
    )
}

export default StepInformation;