export const TOKEN_KEY = "@sistema-app-nova-santa-rita-premiada";
export const FILE_TOKEN_KEY = "@sistema-app-nova-santa-rita-premiada-token-image";
// export const IMAGE_KEY = "@sistema-safepic-image";
// export const NAME_KEY = "@sistema-safepic-name";

export const isAuthenticated = () => {
  return localStorage.getItem(TOKEN_KEY) !== null
  //return true;
};

export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const login = token => {
  localStorage.setItem(TOKEN_KEY, token);
};

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(FILE_TOKEN_KEY);
  localStorage.removeItem("@taxpayer-name");
};

export const setLoggedName = (name) => localStorage.setItem("@taxpayer-name", name);
export const getLoggedName = () => localStorage.getItem("@taxpayer-name");

export const setTokenFile = token => {
  localStorage.setItem(FILE_TOKEN_KEY, token);
};

export const getTokenFile = () => localStorage.getItem(FILE_TOKEN_KEY);