import React, { useState } from "react";
import { Button, TextField, Typography } from '@material-ui/core';
import Theme from '../../../components/views/Theme';
import successIcon from "../../../images/success-icon.png"
import AlertModal from "../../../components/views/AlertModal";
import WarningIcon from '@material-ui/icons/Warning';
import { useNavigate } from 'react-router-dom';

const StepValue = (props) => {

    let navigate = useNavigate();

    const [modalSuccess, setModalSuccess] = useState(false);
    const [keyModalSuccess, setKeyModalSuccess] = useState(Math.random());

    const [modalError, setModalError] = useState(false);
    const [keyModalError, setKeyModalError] = useState(Math.random());

    const [errors, setErrors] = useState([]);

    const handleSubmitForm = async (event) => {
        event.preventDefault();
        let rs = await props.send();
        if (rs.status) {
            _setMessageSuccess(true);
        } else {
            setErrors(rs.message);
            _setMessageError(true);
        }
    }

    const _setMessageSuccess = (option) => {
        setModalSuccess(option);
        setKeyModalSuccess(Math.random());
    }

    const _setMessageError = (option) => {
        setModalError(option);
        setKeyModalError(Math.random());
    }

    const getSuccessMessage = () => {
        return (
            <div style={{...Theme.palette.divModalRegisterSuccess}}>
                <div>
                    <img src={successIcon} className="img-success-icon mb20" alt="Icone sucesso" />
                </div>

                <div>
                    <p style={{...Theme.palette.textModalRegisterSuccessPrimary}}>Nota cadastrada com sucesso!</p>
                </div>

                <br />

                <div style={{...Theme.palette.divCenter, ...Theme.palette.mt20, ...Theme.palette.mb20}}>
                    <Button style={{...Theme.palette.buttonRed, ...Theme.palette.spaceBottom}} onClick={(event) => handleBack(event)}>
                        Voltar ao menu inicial
                    </Button>
                </div>

                <div>
                    <Button style={{...Theme.palette.buttonRed, ...Theme.palette.spaceBottom}} onClick={(event) => handleNewInvoice(event)}>
                        Cadastrar nova Nota Fiscal
                    </Button>
                </div>
            </div>
        )
    }

    const getErrorMessage = () => {
        return (
            <div style={{...Theme.palette.divModalRegisterSuccess}}>
                <div>
                    <WarningIcon style={{...Theme.palette.textColorRed, ...Theme.palette.textSize50}} />
                </div>

                {errors.map((item, index) => {
                    return (
                        <div key={index}>
                            <p style={{...Theme.palette.textModalRegisterSuccessPrimary}}>{item}</p>
                        </div>
                    )
                })}

                <br />

                <div style={{...Theme.palette.divCenter, ...Theme.palette.mt20, ...Theme.palette.mb20}}>
                    <Button style={{...Theme.palette.buttonRed, ...Theme.palette.spaceBottom}} onClick={(event) => handleBack(event)}>
                        Voltar ao menu inicial
                    </Button>
                </div>

                <div>
                    <Button style={{...Theme.palette.buttonRed, ...Theme.palette.spaceBottom}} onClick={(event) => handleNewInvoice(event)}>
                        Tentar novamente
                    </Button>
                </div>
            </div>
        )
    }

    const handleBack = async (event) => {
        event.preventDefault();
        _setMessageSuccess(false);
        _setMessageError(false);
        navigate(`/sistema`);
    }

    const handleNewInvoice = async (event) => {
        event.preventDefault();
        _setMessageSuccess(false);
        _setMessageError(false);
        props.resetInvoice();
    }

    return (
        <div>
            <AlertModal
                open={modalSuccess}
                handleClose={_setMessageSuccess}
                message={null}
                title={null}
                body={getSuccessMessage()}
                key={keyModalSuccess}
            />

            <AlertModal
                open={modalError}
                handleClose={_setMessageError}
                message={null}
                title={null}
                body={getErrorMessage()}
                key={keyModalError}
            />

            <div style={{...Theme.palette.divTitleRegisterInvoices, ...Theme.palette.divSpaceLink, ...Theme.palette.divCenter}}>
                <span style={{...Theme.palette.textColorRed}}>Para finalizar o cadastro clique em &nbsp;
                    <span style={{...Theme.palette.textBold}}>
                        Enviar nota
                    </span>
                </span>
            </div>

            <div style={{...Theme.palette.divSpaceLink, ...Theme.palette.divCenter}}>
                <span style={{...Theme.palette.textColorRed, ...Theme.palette.textBold}}>Atenção</span>
            </div>

            <div style={{...Theme.palette.divSpaceLink, ...Theme.palette.divCenter}}>
                <p style={{...Theme.palette.textColorRed, ...Theme.palette.textSize14}}>Depois de clicar em Envia nota, a nota entra em análise.</p>
                <p style={{...Theme.palette.textColorRed, ...Theme.palette.textSize14}}>Você receberá uma notifiacação com a provação</p>
                <p style={{...Theme.palette.textColorRed, ...Theme.palette.textSize14}}>ou a reprovação da mesma</p>
            </div>

            <div style={{...Theme.palette.divCenter}}>
                <Button style={{...Theme.palette.buttonRed, ...Theme.palette.spaceBottom}} onClick={handleSubmitForm}>
                    Enviar nota
                </Button>
            </div>
        </div>
    );
}

export default StepValue;