import React, { useState, useEffect } from "react";
import Http from "../../components/infrastructures/Http"
import PageLoad from "../../components/views/PageLoad";
import { Typography } from '@material-ui/core';
import Theme from '../../components/views/Theme';
import Certificates from "../../components/views/Certificates";
import { RequestGetCoupons } from "../../components/infrastructures/UrlEndpoints";

const MyCoupons = () => {

    const [loading, setLoading] = useState(false);
    const [keyLoading, setKeyLoading] = useState(Math.random());
    const [coupons, setCoupons] = useState([]);

    useEffect(() => {
        async function processResquetAPI() {
            _setLoading(true);
            await getCoupons();
            _setLoading(false);
        }

        processResquetAPI()
    },[]);

    const _setLoading = (option) => {
        setLoading(option);
        setKeyLoading(Math.random());
    }

    const getCoupons = async () => {
        await Http({
            method: "GET",
            url: RequestGetCoupons(),
        }).then(response => {
            setCoupons(response.data)
        }).catch(err => {
            setCoupons([]);
        });
    }

    return (
        <div style={{width: "400px", margin: "auto"}}>
            <PageLoad open={loading} key={keyLoading} />

            <div style={{margin:"auto", paddingTop: "20px"}}>
                <Typography variant="h6" component="h1" align="left" style={Theme.palette.textColorRed}>Meus certificados</Typography>
            </div>

            {(coupons.length !== 0) && (
                <div style={{width: "400px", display: "grid", gridGap: "10px", gridTemplateColumns: "repeat(4, 100px)"}}>
                    {coupons.map((item, index) => {
                        return <Certificates coupon={item} key={index} />
                    })}
                </div>
            )}

            {(coupons.length === 0) && (
                <div style={{textAlign: "center"}}>
                    <p style={{fontSize: "16px", color:"#707070"}}>Você ainda não possui nenhuma certificado.</p>
                    <p style={{fontSize: "16px", color:"#707070"}}>Clique no botão <span style={{fontWeight: "bold"}}>Cadastrar Notas</span> para iniciar</p>
                    <p style={{fontSize: "16px", color:"#707070"}}>E aguarde a aprovação.</p>
                </div>
            )}


                {/*<Certificates coupon="A00002" />
                 <Certificates coupon="A00003" />
                <Certificates coupon="A00004" />
                <Certificates coupon="A00005" />
                <Certificates coupon="A00006" />
                <Certificates coupon="A00007" />
                <Certificates coupon="A00008" />
                <Certificates coupon="A00009" />
                <Certificates coupon="A00010" />
                <Certificates coupon="A00011" />
                <Certificates coupon="A00012" /> */}

        </div>
    );
};

export default MyCoupons;