import axios from "axios";
import { getToken } from "./Auth";
import { getUrl } from "./Environment";

// //let url = "https://server-teste.campobom.rs.gov.br";
// let url = "http://localhost:4000";
// if (process.env.REACT_APP_ENV === "development") {
//   url = "http://localhost:4000";
// } else if (process.env.REACT_APP_ENV === "test") {
//   url = "https://d218fe2dd603.ngrok.io/api";
// } else if (process.env.REACT_APP_ENV === "production") {
//   url = "http://177.10.0.125:5006";
// }

let url = getUrl();

const Http = axios.create({
  baseURL: url
});

Http.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
});

export default Http;