import React from 'react';
import Theme from './Theme';

const StepByStep = (props) => {

    const getSteps = () => {
        let steps = [];
        for(var i = 1; i <= props.size; i++) {
            let active = (props.active === i)
            steps.push({active});
        }
        return steps;
    }

    const getStyle = (option) => {
        if (option) {
            return {...Theme.palette.stepActive};
        }

        return {...Theme.palette.stepInactive};
    }

    return (
        <div>
            {getSteps().map((item, index) => {
                return <span key={index} style={getStyle(item.active)}></span>
            })}
        </div>
    )
}

export default StepByStep;