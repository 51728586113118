import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import Home from "../../views/public/Home";
import Login from "../../views/public/Login";
import Register from "../../views/public/Register";
import NotFound from "../../views/public/NotFound";
import Main from "../../views/system/Main";
import ListInvoices from "../../views/system/ListInvoices";
import RegisterInvoices from "../../views/system/RegisterInvoices";
import MyCoupons from "../../views/system/MyCoupons";
import TaxPayer from "../../views/system/TaxPayer";
import AlterPassword from "../../views/system/AlterPassword";
import PrivacyPolicy from "../../views/public/PrivacyPolicy";

import { isAuthenticated } from "../../components/infrastructures/Auth";

function PrivateRoute({ children }) {
    const auth = isAuthenticated();
    return auth ? children : <Navigate to="/login" />;
}

const Router = () => (
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<Home />}  exact />
            <Route path="/login" element={<Login />} exact />
            <Route path="/cadastrar" element={<Register />} exact />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} exact />


            <Route
                path="/sistema"
                element={
                    <PrivateRoute>
                        <Main breadcrumbs={["Home", "Menu"]} title={"Listar notas"}>
                            <ListInvoices />
                        </Main>
                    </PrivateRoute>
                }
            />

            <Route
                path="/notas"
                element={
                    <PrivateRoute>
                        <Main breadcrumbs={["Home", "Menu", "Cadastrar notas"]} title={"Cadastrar notas"}>
                            <RegisterInvoices />
                        </Main>
                    </PrivateRoute>
                }
            />

            <Route
                path="/listar"
                element={
                    <PrivateRoute>
                        <Main breadcrumbs={["Home", "Menu", "Minhas notas"]} title={"Minhas notas"}>
                            <ListInvoices />
                        </Main>
                    </PrivateRoute>
                }
            />

            <Route
                path="/meus-cupons"
                element={
                    <PrivateRoute>
                        <Main breadcrumbs={["Home", "Menu", "Meus cupons"]} title={"Meus cupons"}>
                            <MyCoupons />
                        </Main>
                    </PrivateRoute>
                }
            />

            <Route
                path="/menus-dados"
                element={
                    <PrivateRoute>
                        <Main breadcrumbs={["Home", "Menu", "Meus dados"]} title={"Meus dados"}>
                            <TaxPayer />
                        </Main>
                    </PrivateRoute>
                }
            />

            <Route
                path="/alterar-senha"
                element={
                    <PrivateRoute>
                        <Main breadcrumbs={["Home", "Menu", "Alterar senha"]} title={"Alterar senha"}>
                            <AlterPassword />
                        </Main>
                    </PrivateRoute>
                }
            />

            <Route path="*" element={<NotFound />} />
        </Routes>
    </BrowserRouter>
);

export default Router;