import React, { useState, useEffect } from 'react';
import { Button, Typography, TextField } from '@material-ui/core';
import Theme from '../../../components/views/Theme';
import { ThemeProvider } from '@material-ui/styles';
import StepByStep from "../../../components/views/StepByStep";

const StepNumber = (props) => {

    let errors = props.errors;
    let data = props.data;

    const [document, setDocument] = useState("");

    const [error, setError] = useState({
        document: {validate: true, message: null}
    });

    const validateForm = () => {
        let _error = {...error};
        let _verify = false;

        if (document.trim() === "") {
            _error.document.validate = false;
            _error.document.message = "Por favor preencha o campo. Número do documento é obrigatório.";
            _verify = true;
        }

        setError(_error);
        return _verify;
    }

    useEffect(() => {
        let _error = {...error};
        errors.map(err => {
            _error[err.field.toLowerCase()].validate = false;
            _error[err.field.toLowerCase()].message = err.description.join();
        })
        setError(_error);
    }, [errors]);

    useEffect(() => {
        setDocument(data.document || "");
    }, [data]);

    const handleSubmitForm = (event) => {
        event.preventDefault();

        if (validateForm()) return;

        props.setPreviewData({
            document: document
        });

        props.setStep(3);
    }

    const buttonStyle = () => {
        if (document === "") {
            return {...Theme.palette.buttonRedDisable, ...Theme.palette.spaceBottom};
        }

        return {...Theme.palette.buttonRed, ...Theme.palette.spaceBottom};
    }

    return (
        <div>
            <div style={{...Theme.palette.divTitleRegisterInvoices}}>
                <Typography variant="h6" component="h1" align="left" style={Theme.palette.textColorRed}>Cadastre o número da nota fiscal</Typography>
            </div>

            <ThemeProvider theme={Theme}>
                <TextField
                    value={document}
                    onChange={(event) => setDocument(event.target.value)}
                    onFocus={(event) => {
                        let _error = {...error};
                        _error.document.validate = true;
                        _error.document.message = "";
                        setError(_error);
                    }}
                    error={!error.document.validate}
                    helperText={error.document.message}
                    color="primary"
                    id="document"
                    name="document"
                    label="Número da nota fiscal"
                    size="small"
                    margin="dense"
                    type="text"
                    placeholder="Preencha o campo"
                    fullWidth
                />
            </ThemeProvider>

            <div style={{...Theme.palette.divSpaceLink, ...Theme.palette.divCenter}}>
                <StepByStep size={3} active={2} />
            </div>

            <div style={{...Theme.palette.divCenter}}>
                <Button disabled={document === ""} style={buttonStyle()} onClick={handleSubmitForm}>
                    Próximo
                </Button>
            </div>
        </div>
    );
}

export default StepNumber;
