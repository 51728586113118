// import React from "react";
// const valorPadrao = "teste 123";
// const ApplicationContext = React.createContext(valorPadrao);
// export default ApplicationContext;

import React from "react";
const ApplicationContext = React.createContext();
ApplicationContext.UserName = "";
export const useApplicationContext = () => React.useContext(ApplicationContext);
export const ApplicationContextProvider = ({ children, initialState, reducer }) => {
    const [globalState, dispatch] = React.useReducer(reducer, initialState);

    return (
          <ApplicationContext.Provider value={[globalState, dispatch]}>{children}</ApplicationContext.Provider>
    );
};