import React from "react";
import { useNavigate } from 'react-router-dom';
import { Button } from '@material-ui/core';
import Theme from '../../components/views/Theme'

const Home = () => {

    let navigate = useNavigate();

    const handleLogin = (event) => {
        event.preventDefault();
        navigate(`/login`);
    }

    const handleRegister = (event) => {
        event.preventDefault();
        navigate(`/cadastrar`);
    }

    return (
        <div style={Theme.palette.BodyImage}>
            <div style={Theme.palette.HomeButtons} className="div-not-mobile">
                <Button style={{...Theme.palette.buttonRed, ...Theme.palette.spaceRight}} onClick={handleLogin}>
                    Login
                </Button>

                <Button style={{...Theme.palette.buttonWhite, ...Theme.palette.spaceLeft}} onClick={handleRegister}>
                    Fazer cadastro
                </Button>
            </div>

            <div style={Theme.palette.HomeButtons} className="div-mobile">
                <div>
                    <Button style={{...Theme.palette.buttonRed, ...Theme.palette.spaceBottom}} onClick={handleLogin}>
                        Login
                    </Button>
                </div>
                <div>
                    <Button style={{...Theme.palette.buttonWhite}} onClick={handleRegister}>
                        Fazer cadastro
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default Home;