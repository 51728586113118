import React, { useState } from 'react';
import analyzing from "../../images/analyzing.png";
import okay from "../../images/okay.png";
import reject from "../../images/reject.png";
import { numberFormat, getTypeFile, formatDateHourBR } from "./Functions";
import AlertModal from './AlertModal';
import Theme from './Theme';
import { Button } from '@material-ui/core';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

const InvoiceCard = (props) => {

    const [open, setOpen] = useState(false);

    const handleOpenModal = (event) => {
        event.preventDefault();
        setOpen(true);
    }

    const handleOpenFileNewTab = (event) => {
        event.preventDefault();
        window.open(props.item.UrlFile, '_blank').focus();
    }


    const getSuccessMessage = () => {
        let _typeFile = (getTypeFile(props.item.FileName) === "PDF");
        let _style = {padding: "15px"};
        if (!_typeFile) {
            _style.height = "80vh";
            _style.overflow = "auto";
        }

        let _mensagens = (props.item.MessageDocument == null &&
            props.item.MessageFile == null &&
            props.item.MessageValue == null &&
            props.item.MessageCpf == null);

        console.log("_mensagens", _mensagens);



        return (
            <div>
                <div style={_style}>
                    <div style={{marginBottom: "10px"}}>
                        <p style={{margin:"0px", color: "#707070", fontSize:"11px"}}>Data de cadastro</p>
                        <p style={{margin:"0px", color: "#ed3136", fontSize:"14px"}}>{formatDateHourBR(props.item.Data) || ""}</p>
                    </div>

                    <div style={{display: "grid", gridTemplateColumns: "48% 48%", gridGap: "4%", backgroundColor: "#fff", marginBottom: "10px"}}>
                        <div style={{background:"#f7f7f7", borderRadius:"10px", padding: "6px"}}>
                            <p style={{display: "flex", alignItems: "center", justifyContent: "stretch", margin:"0px", height: "20px", color: "#707070", fontSize:"11px"}}>Valor</p>
                            <p style={{display: "flex", alignItems: "center", justifyContent: "stretch", margin:"0px", height: "40px", fontSize: "12px", fontWeight: "bold", color: "#ed3136"}}>
                                R$ {numberFormat(props.item.Value, 2, ',', '.') || "0,00"}
                            </p>
                        </div>

                        <div style={{background:"#f7f7f7", borderRadius:"10px", padding: "6px"}}>
                            <p style={{display: "flex", alignItems: "center", justifyContent: "stretch", margin:"0px", height: "20px", color: "#707070", fontSize:"11px"}}>Status</p>

                            {(props.item.Status === "APROVADA") && (
                                <p style={{display: "flex", alignItems: "center", justifyContent: "stretch", margin:"0px", height: "40px"}}>
                                    <img src={okay} alt="Aprovado" />
                                    <span style={{fontSize: "9px", color: "#797979"}}>Aprovada</span>
                                </p>
                            )}

                            {(props.item.Status === "REJEITADA") && (
                                <p style={{display: "flex", alignItems: "center", justifyContent: "stretch", margin:"0px", height: "40px"}}>
                                    <img src={reject} alt="Rejeitada" />
                                    <span style={{fontSize: "9px", color: "#797979"}}>Rejeitada</span>
                                </p>
                            )}

                            {(props.item.Status === "EM_ANALISE") && (
                                <p style={{display: "flex", alignItems: "center", justifyContent: "stretch", margin:"0px", height: "40px"}}>
                                    <img src={analyzing} alt="Em análise" />
                                    <span style={{fontSize: "9px", color: "#797979"}}>Em análise</span>
                                </p>
                            )}

                        </div>
                    </div>

                    {(!_mensagens) && (
                        <div style={{background:"#f7f7f7", borderRadius:"10px", padding: "6px", marginBottom: "10px"}}>
                            {(props.item.MessageDocument !== "") && (<p style={{color: "grey", fontSize:"12px", fontWeight:"bold"}}>{props.item.MessageDocument}</p>)}
                            {(props.item.MessageFile !== "") && (<p style={{color: "grey", fontSize:"12px", fontWeight:"bold"}}>{props.item.MessageFile}</p>)}
                            {(props.item.MessageValue !== "") && (<p style={{color: "grey", fontSize:"12px", fontWeight:"bold"}}>{props.item.MessageValue}</p>)}
                            {(props.item.MessageCpf !== "") && (<p style={{color: "grey", fontSize:"12px", fontWeight:"bold"}}>{props.item.MessageCpf}</p>)}
                        </div>
                    )}

                    <div>
                        {(_typeFile)
                            ? ( <div style={{display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "20px"}}>
                                    <PictureAsPdfIcon style={{...Theme.palette.textColorRed}}/>
                                    <a style={{fontSize: "14px", color: "#ed3136", cursor: "pointer", marginLeft: "10px"}} onClick={(event => handleOpenFileNewTab(event))}>Abrir em nova aba</a>
                                </div>)
                                : <img src={props.item.UrlFile} style={{width: "100%"}}/>}
                    </div>

                    <div style={{textAlign: "center"}}>
                        <Button style={{...Theme.palette.buttonRed}} onClick={() => setOpen(false)}>
                            Fechar
                        </Button>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <AlertModal
                open={open}
                handleClose={() => setOpen(false)}
                message={null}
                title={null}
                body={getSuccessMessage()}
                key={open}
            />

            <div style={{backgroundColor: "#fff", boxShadow: "0 3px 6px rgb(0 0 0 / 16%)", borderRadius: "20px"}}>
                <div style={{padding: "15px"}}>
                    <div style={{marginBottom: "10px"}}>
                        <p style={{margin:"0px", color: "#707070", fontSize:"11px"}}>Data de cadastro</p>
                        <p style={{margin:"0px", color: "#ed3136", fontSize:"14px"}}>{formatDateHourBR(props.item.Data) || ""}</p>
                    </div>

                    <div style={{display: "grid", gridTemplateColumns: "48% 48%", gridGap: "4%", backgroundColor: "#fff", marginBottom: "10px"}}>
                        <div style={{background:"#f7f7f7", borderRadius:"10px", padding: "6px"}}>
                            <p style={{display: "flex", alignItems: "center", justifyContent: "stretch", margin:"0px", height: "20px", color: "#707070", fontSize:"11px"}}>Valor</p>
                            <p style={{display: "flex", alignItems: "center", justifyContent: "stretch", margin:"0px", height: "40px", fontSize: "12px", fontWeight: "bold", color: "#ed3136"}}>
                                R$ {numberFormat(props.item.Value, 2, ',', '.') || "0,00"}
                            </p>
                        </div>

                        <div style={{background:"#f7f7f7", borderRadius:"10px", padding: "6px"}}>
                            <p style={{display: "flex", alignItems: "center", justifyContent: "stretch", margin:"0px", height: "20px", color: "#707070", fontSize:"11px"}}>Status</p>

                            {(props.item.Status === "APROVADA") && (
                                <p style={{display: "flex", alignItems: "center", justifyContent: "stretch", margin:"0px", height: "40px"}}>
                                    <img src={okay} alt="Aprovado" />
                                    <span style={{fontSize: "9px", color: "#797979"}}>Aprovada</span>
                                </p>
                            )}

                            {(props.item.Status === "REJEITADA") && (
                                <p style={{display: "flex", alignItems: "center", justifyContent: "stretch", margin:"0px", height: "40px"}}>
                                    <img src={reject} alt="Rejeitada" />
                                    <span style={{fontSize: "9px", color: "#797979"}}>Rejeitada</span>
                                </p>
                            )}

                            {(props.item.Status === "EM_ANALISE") && (
                                <p style={{display: "flex", alignItems: "center", justifyContent: "stretch", margin:"0px", height: "40px"}}>
                                    <img src={analyzing} alt="Em análise" />
                                    <span style={{fontSize: "9px", color: "#797979"}}>Em análise</span>
                                </p>
                            )}

                        </div>
                    </div>

                    <div style={{background:"#f7f7f7", borderRadius:"10px", padding: "6px", marginBottom: "10px"}}>
                        <p style={{margin:"0px", height: "20px", color: "#707070", fontSize:"11px"}}>Número da nota</p>
                        <p style={{margin:"0px", fontSize: "12px", color: "#797979", fontWeight: "bold", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
                            {props.item.Document}
                        </p>
                    </div>

                    <div>
                        <p style={{margin:"0px", textAlign:"center"}}>
                            <a style={{fontSize: "14px", color: "#ed3136", cursor: "pointer"}} onClick={(event => handleOpenModal(event))}>Detalhes</a>
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default InvoiceCard;