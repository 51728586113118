import React, { useState } from 'react';
import { Button, Typography } from '@material-ui/core';
import Theme from '../../../components/views/Theme';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import AlertModal from "../../../components/views/AlertModal";
import StepByStep from "../../../components/views/StepByStep";
import { inArray } from "../../../components/views/Functions";

const StepUpload = (props) => {

    const [open, setOpen] = useState(false);
    const [message] = useState([
        "Arquivo não pode ser enviado para upload.",
        "Verifique a extensão do mesmo.",
        "Extensões permitidas: PNG, JPEG, JPG, PDF."
    ]);

    const [fileType, setFileType] = useState(0);
    const [imgSource, setImgSource] = useState(null);

    const handleClose = (event) => {
        setOpen(false);
    }

    const handleSelectFile = (event) => {
        event.preventDefault();
        document.getElementById("upload-file").click();
    }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const handleUploadFile = async () => {
        let file = document.getElementById("upload-file").files[0];
        if (typeof file === "undefined") {
            setFileType(0);
            return;
        }

        let fileExtension = file.name.split(".");
        fileExtension = fileExtension[fileExtension.length - 1];
        fileExtension = fileExtension.toUpperCase();
        let extensions = ["PNG", "JPEG", "JPG", "PDF"];

        if(!inArray(fileExtension, extensions)) {
            setOpen(true);
            return false;
        }

        if (fileExtension !== "PDF") {
            await toBase64(file).then(result => {
                setFileType(1);
                setImgSource(result);
            }).catch(err => {
                setOpen(true);
            });
        } else {
            setFileType(2);
        }
    }

    const viewUpload = (option) => {
        if (option == 1) {
            return (
                <img src={imgSource} style={{...Theme.palette.divUploadInvoices}} onClick={(event) => handleSelectFile(event)}/>
            )
        }

        if (option == 2) {
            return (
                <div style={{...Theme.palette.divUploadInvoices}} onClick={(event) => handleSelectFile(event)}>
                    <p style={{...Theme.palette.textUploadInvoices}}>
                        <PictureAsPdfIcon style={{...Theme.palette.textColorRed}} /><br />
                        nota fiscal em PDF
                    </p>
                </div>
            )
        }

        return (
            <div style={{...Theme.palette.divUploadInvoices}} onClick={(event) => handleSelectFile(event)}>
                <p style={{...Theme.palette.textUploadInvoices}}>
                    Clique aqui<br />
                    e carregue a<br />
                    nota fiscal
                </p>
            </div>
        )
    }

    const handleSubmitForm = (event) => {
        event.preventDefault();
        props.setPreviewData({
            file: document.getElementById("upload-file").files[0],
            type: fileType
        });
        props.setStep(2);
    }

    const buttonStyle = () => {
        if (fileType === 0) {
            return {...Theme.palette.buttonRedDisable, ...Theme.palette.spaceBottom};
        }

        return {...Theme.palette.buttonRed, ...Theme.palette.spaceBottom};
    }

    return (
        <div>
            <AlertModal
                open={open}
                handleClose={handleClose}
                message={message}
                title="Alerta"
                key={open}
            />

            <div style={{...Theme.palette.divTitleRegisterInvoices}}>
                <Typography variant="h6" component="h1" align="left" style={Theme.palette.textColorRed}>Carregar Nota Fiscal</Typography>
                <p style={{...Theme.palette.subTitleRegisterInvoices}}>Clique no retângulo abaixo para carregar a nota fiscal</p>
            </div>

            {viewUpload(fileType)}

            <input type="file" id="upload-file" style={{...Theme.palette.displayNone}} onChange={() => handleUploadFile()}/>

            <div style={{...Theme.palette.divSpaceLink, ...Theme.palette.divCenter}}>
                <StepByStep size={3} active={1} />
            </div>

            <div style={{...Theme.palette.divCenter}}>
                <Button disabled={fileType === 0} style={buttonStyle()} onClick={handleSubmitForm}>
                    Próximo
                </Button>
            </div>
        </div>
    );
}

export default StepUpload;
