import React, { useState, useEffect } from "react";
import StepPersonalInformation from "./tax-payer-steps/StepPersonalInformation";
import StepContactInformation from "./tax-payer-steps/StepContactInformation";
import StepAddressInformation from "./tax-payer-steps/StepAddressInformation";
import Http from "../../components/infrastructures/Http"
import PageLoad from "../../components/views/PageLoad";
import { Typography, Button } from '@material-ui/core';
import Theme from '../../components/views/Theme';
import TaxPayerStep from "../../components/views/TaxPayerStep";
import { formatDateDB } from "../../components/views/Functions";
import successIcon from "../../images/success-icon.png"
import AlertModal from "../../components/views/AlertModal";
import WarningIcon from '@material-ui/icons/Warning';
import { useApplicationContext } from '../../components/infrastructures/Context';
import { changeUserName } from "../../components/infrastructures/Reducer";

const TaxPayer = () => {

    const [application, dispatch] = useApplicationContext();

    const [data, setData] = useState({});
    const [step, setStep] = useState(0);

    const [loading, setLoading] = useState(false);
    const [keyLoading, setKeyLoading] = useState(Math.random());
    const [keyData, setKeyData] = useState(Math.random());

    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);

    const [errors, setErrors] = useState([]);
    const [message, setMessage] = useState([]);

    const [modalSuccess, setModalSuccess] = useState(false);
    const [keyModalSuccess, setKeyModalSuccess] = useState(Math.random());

    const [modalError, setModalError] = useState(false);
    const [keyModalError, setKeyModalError] = useState(Math.random());

    useEffect(() => {
        async function processResquetAPI() {
            _setLoading(true);
            await getCountries();
            await getStates();
            await getCities();
            await getTaxPayer();
            _setLoading(false);
        }

        processResquetAPI()
    },[]);

    useEffect(() => {
        setKeyData(Math.random());
    },[data]);

    const send = async (info) => {
        let formData = setPreviewData(info);
        formData.Cpf = formData.Cpf.split(".").join("").split("-").join("")
        formData.DateBorn = formatDateDB(formData.DateBorn);
        _setLoading(true);
        let rs = await Http({
            method: "PUT",
            url: "/api/v1/taxpayer",
            data: formData
        }).then(response => {
            _setLoading(false);
            _setMessageSuccess(true);
            dispatch(changeUserName(formData.Name));
        }).catch(err => {
            _setLoading(false);
            setStep(0);
            let message = ["Falha inesperada. Entre em contato com o Administrador."];
            if (Array.isArray(err.response.data)) {
                setErrors(err.response.data);
                message = [];
                message.push("Não foi possível atualizar as informações.");
                message.push("Verifique e corriga a informação.");
                message.push("Ao final reenvie os dados.");
            }
            setMessage(message);
            _setMessageError(true);
        });
    }

    const _setLoading = (option) => {
        setLoading(option);
        setKeyLoading(Math.random());
    }

    const setPreviewData = (info) => {
        let _data = {...data, ...info};
        setData(_data);
        return _data;
    }

    const steps = [
        <StepPersonalInformation
            setStep={setStep}
            setPreviewData={setPreviewData}
            data={data}
            keyData={keyData}
            errors={errors}/>,

        <StepAddressInformation
            setStep={setStep}
            setPreviewData={setPreviewData}
            keyData={keyData}
            data={data}
            countries={countries}
            states={states}
            cities={cities}
            errors={errors}/>,

        <StepContactInformation
            send={send}
            keyData={keyData}
            data={data}
            errors={errors}/>,
    ];

    const getTaxPayer = async () => {
        await Http({
            method: "GET",
            url: "/api/v1/taxpayer",
        }).then(response => {
            setData(response.data)
        }).catch(err => {
            setData({});
        });
    }

    const getCountries = async () => {
        await Http({
            method: "GET",
            url: "/api/v1/countries",
        }).then(response => {
            setCountries(response.data)
        }).catch(err => {
            setCountries([]);
        });
    }

    const getStates = async () => {
        await Http({
            method: "GET",
            url: "/api/v1/states",
        }).then(response => {
            setStates(response.data)
        }).catch(err => {
            setStates([]);
        });
    }

    const getCities = async () => {
        await Http({
            method: "GET",
            url: "/api/v1/cities",
        }).then(response => {
            setCities(response.data)
        }).catch(err => {
            setCities([]);
        });
    }

    const _setMessageSuccess = (option) => {
        setModalSuccess(option);
        setKeyModalSuccess(Math.random());
    }

    const _setMessageError = (option) => {
        setModalError(option);
        setKeyModalError(Math.random());
    }

    const handleOk = (event) => {
        event.preventDefault();
        _setMessageSuccess(false);
        _setMessageError(false);
    }

    const getSuccessMessage = () => {
        return (
            <div style={{...Theme.palette.divModalRegisterSuccess}}>
                <div>
                    <img src={successIcon} className="img-success-icon mb20" alt="Icone sucesso" />
                </div>

                <div>
                    <p style={{...Theme.palette.textModalRegisterSuccessPrimary}}>Dados atualizados com sucesso!</p>
                </div>

                <br />

                <div style={{...Theme.palette.divCenter, ...Theme.palette.mt20}}>
                    <Button style={{...Theme.palette.buttonRed, ...Theme.palette.spaceBottom}} onClick={(event) => handleOk(event)}>
                        Ok
                    </Button>
                </div>
            </div>
        )
    }

    const getErrorMessage = () => {
        return (
            <div style={{...Theme.palette.divModalRegisterSuccess}}>
                <div>
                    <WarningIcon style={{...Theme.palette.textColorRed, ...Theme.palette.textSize50}} />
                </div>

                {message.map((item, index) => {
                    return (
                        <div key={index}>
                            <p style={{...Theme.palette.textModalRegisterSuccessPrimary}}>{item}</p>
                        </div>
                    )
                })}

                <br />

                <div style={{...Theme.palette.divCenter, ...Theme.palette.mt20}}>
                    <Button style={{...Theme.palette.buttonRed, ...Theme.palette.spaceBottom}} onClick={(event) => handleOk(event)}>
                        Ok
                    </Button>
                </div>
            </div>
        )
    }

    return (
        <div style={{width: "400px", margin: "auto"}}>

            <AlertModal
                open={modalSuccess}
                handleClose={_setMessageSuccess}
                message={null}
                title={null}
                body={getSuccessMessage()}
                key={keyModalSuccess}
            />

            <AlertModal
                open={modalError}
                handleClose={_setMessageError}
                message={null}
                title={null}
                body={getErrorMessage()}
                key={keyModalError}
            />

            <PageLoad open={loading} key={keyLoading} />

            <div style={{margin:"auto", paddingTop: "20px", paddingBottom: "20px"}}>
                <Typography variant="h6" component="h1" align="left" style={Theme.palette.textColorRed}>Meus dados</Typography>
            </div>

            <div style={{display: "grid", gridTemplateColumns: "32% 32% 32%", gridGap: "2%"}}>
                <TaxPayerStep step={1} title="Informações" active={step === 0} />
                <TaxPayerStep step={2} title="Endereço" active={step === 1} />
                <TaxPayerStep step={3} title="Contato" active={step === 2} />
            </div>

            {steps[step]}
        </div>
    );
};

export default TaxPayer;