import React, { useLayoutEffect, useState, useEffect } from "react";
import { Typography } from '@material-ui/core';
import Theme from '../../components/views/Theme';
import InvoiceCard from "../../components/views/InvoiceCard";
import Http from "../../components/infrastructures/Http";
import PageLoad from "../../components/views/PageLoad";
import BoxInformationList from "../../components/views/BoxInformationList";
import { numberToMoney } from "../../components/views/Functions";
import { RequestGetInvoices, RequestGetCoupons } from "../../components/infrastructures/UrlEndpoints";

const ListInvoices = (props) => {

    const [width, setWidth] = useState(0);
    const [templateColumn, setTemplateColumn] = useState(0);
    const [templateColumnHeader, setTemplateColumnHeader] = useState(0);
    const [list, setList] = useState([]);
    const [coupons, setCoupons] = useState([]);
    const [loading, setLoading] = useState(false);
    const [keyLoading, setKeyLoading] = useState(Math.random());
    const [total, setTotal] = useState(0);

    const updateSize = () => {
        if (window.innerWidth <= 620) {
            setTemplateColumn("300px");
            setTemplateColumnHeader("0px 180px 100px");
            setWidth("300px");
        } else if (window.innerWidth > 620 && window.innerWidth < 940) {
            setTemplateColumn("300px 300px");
            setTemplateColumnHeader("300px 200px 100px");
            setWidth("620px");
        } else {
            setTemplateColumn("300px 300px 300px");
            setTemplateColumnHeader("620px 200px 100px");
            setWidth("940px");
        }
    }

    useLayoutEffect(() => {
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    useEffect(() => {
        async function processResquetAPI() {
            _setLoading(true);
            await getInvoices();
            await getCoupons();
            _setLoading(false);
        }

        processResquetAPI()
    },[]);


    useEffect(() => {
        let _total = 0;
        list.map(item => {
            if (item.Status === "APROVADA") {
                _total += parseFloat(item.Value);
            }
        });
        setTotal("R$ " + numberToMoney(_total));
    },[list]);

    const _setLoading = (option) => {
        setLoading(option);
        setKeyLoading(Math.random());
    }

    const getInvoices = async () => {
        await Http({
            method: "GET",
            url: RequestGetInvoices(),
        }).then(response => {
            setList(response.data)
        }).catch(err => {
            setList([]);
        });
    }

    const getCoupons = async () => {
        await Http({
            method: "GET",
            url: RequestGetCoupons(),
        }).then(response => {
            setCoupons(response.data)
        }).catch(err => {
            setCoupons([]);
        });
    }

    return (
        <div>
            <PageLoad open={loading} key={keyLoading} />

            <div style={{width: width, margin:"auto", paddingTop: "20px"}}>
                <Typography variant="h6" component="h1" align="left" style={Theme.palette.textColorRed}>Minha notas cadastradas</Typography>
            </div>

            {(list.length !== 0) && (
                <div style={{width: width, margin:"auto", marginTop: "20px"}}>
                    <div style={{display: "grid", gridTemplateColumns: templateColumnHeader, gridGap: "10px", backgroundColor: "#F7F7F7", color: "#444"}}>
                        <div></div>

                        <BoxInformationList
                            title="Valor das notas aprovadas"
                            info={total} />

                        <BoxInformationList
                            title="Cupons"
                            info={coupons.length} />
                    </div>
                </div>
            )}

            <div style={{width: width, margin:"auto", marginTop: "20px"}}>
                {(list.length !== 0) && (
                    <div style={{display: "grid", gridTemplateColumns: templateColumn, gridGap: "20px", backgroundColor: "#F7F7F7", color: "#444"}}>
                        {list.map((item, index) => {
                            return <InvoiceCard key={index} item={item} />
                        })}
                    </div>
                )}

                {(list.length === 0) && (
                    <div style={{textAlign: "center"}}>
                        <p style={{fontSize: "16px", color:"#707070"}}>Você ainda não possui nenhuma nota cadastrada.</p>
                        <p style={{fontSize: "16px", color:"#707070"}}>Clique no botão <span style={{fontWeight: "bold"}}>Cadastrar Notas</span> para iniciar</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ListInvoices;