import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal,CircularProgress } from '@material-ui/core';
import Theme from './Theme';
import { ThemeProvider } from '@material-ui/styles';

function getModalStyle() {

    const left = ((window.innerWidth - 400) / 2) + "px";
    const top = "150px";

    return {
        top: `${top}`,
        left: `${left}`,
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 370,
        borderRadius: "20px",
        padding: "15px",
        textAlign: "center"
    },
}));

export default function PageLoad(props) {
    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);
    const [open] = React.useState(props.open || false);

    const body = (
        <div style={modalStyle} className={classes.paper}>
            <ThemeProvider theme={Theme}>
                <CircularProgress color="primary" />
            </ThemeProvider>
        </div>
    );

    return (
        <Modal
            disablePortal
            disableEnforceFocus
            disableAutoFocus
            aria-labelledby="server-modal-title"
            aria-describedby="server-modal-description"
            open={open}>
            {body}
        </Modal>
    );
}