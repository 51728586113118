import React from "react";
import { useNavigate } from 'react-router-dom';
import { Button, Typography } from '@material-ui/core';
import Theme from '../../components/views/Theme';
import logo from "../../images/nsr-premiada.png";

const NotFound = () => {
    let navigate = useNavigate();

    const handleBack = (event) => {
        event.preventDefault();
        navigate(`/`);
    }

    return (
        <div style={Theme.palette.divPageLogin}>
            <form>
                <div style={Theme.palette.divCenter}>
                    <img src={logo} style={Theme.palette.imgPageLogin} alt="Nova Santa Rita Premiada" />
                </div>

                <Typography variant="h6" component="h1" align="left" style={{...Theme.palette.divCenter, ...Theme.palette.textColorRed}}>Página não encontrada</Typography>

                <div style={{...Theme.palette.divSpaceLink, ...Theme.palette.divCenter}}>
                    <Button style={{...Theme.palette.buttonWhite}} onClick={handleBack}>
                        Voltar a página inicial
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default NotFound;