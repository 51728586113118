import React, { useState } from "react";
import StepInformation from "./register-invoices-steps/StepInformation";
import StepUpload from "./register-invoices-steps/StepUpload";
import StepNumber from "./register-invoices-steps/StepNumber";
import StepValue from "./register-invoices-steps/StepValue";
import StepSend from "./register-invoices-steps/StepSend";
import Http from "../../components/infrastructures/Http"
import PageLoad from "../../components/views/PageLoad";
import { RequestSendInvoiceImage, RequestSendInvoicePdf } from "../../components/infrastructures/UrlEndpoints";

const RegisterInvoices = () => {

    const [data, setData] = useState({});
    const [errors, setErrors] = useState([]);
    const [step, setStep] = useState(localStorage.getItem("notShowMessage") ? 1 : 0);
    const [loading, setLoading] = useState(false);
    const [keyLoading, setKeyLoading] = useState(Math.random());

    const send = async () => {
        const formData = new FormData();
        formData.append("File", data.file);
        formData.append("Document", data.document);
        formData.append("Value", data.value);
        let url = (data.type === 1) ? RequestSendInvoiceImage() : RequestSendInvoicePdf();

        _setLoading(true);

        let rs = await Http({
            method: "POST",
            url: url,
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then(response => {
            _setLoading(false);
            return {
                "status": true,
                "message" : null,
            }
        }).catch(err => {
            _setLoading(false);
            let message = [];
            if (err.response.status == 400) {
                if (Array.isArray(err.response.data.message)) {
                    setErrors(err.response.data.message);
                    setStep(2);
                    err.response.data.message.map(item => {
                        if (item.field.toLowerCase() == "message") {
                            message.push(item.description.join())
                        }
                    });
                }
            }

            return {
                "status": false,
                "message" : message
            }
        });

        return rs;
    }

    const _setLoading = (option) => {
        setLoading(option);
        setKeyLoading(Math.random());
    }

    const setPreviewData = (info) => {
        let _data = {...data, ...info};
        setData(_data);
    }

    const resetInvoice = () => {
        setData({});
        setStep(localStorage.getItem("notShowMessage") ? 1 : 0);
    }

    const steps = [
        <StepInformation setStep={setStep}/>,

        <StepUpload
            setStep={setStep}
            data={data}
            errors={errors}
            setPreviewData={setPreviewData}/>,

        <StepNumber
            setStep={setStep}
            data={data}
            errors={errors}
            setPreviewData={setPreviewData}/>,

        <StepValue
            setStep={setStep}
            data={data}
            errors={errors}
            setPreviewData={setPreviewData}/>,

        <StepSend
            send={send}
            data={data}
            errors={errors}
            resetInvoice={resetInvoice}/>
    ];

    return (
        <div style={{width: "400px", margin: "auto"}}>
            <PageLoad open={loading} key={keyLoading} />
            {steps[step]}
        </div>
    );
};

export default RegisterInvoices;